import { AfterContentInit, AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { getUFs, UF } from 'src/app/shared/models/UF';
import { StatusFilter } from 'src/app/shared/paginator/enum/status.filter.enum';
import { ColumnType } from 'src/app/shared/paginator/model/column.model';
import { PaginatorComponent } from 'src/app/shared/paginator/paginator.component';
import { GenericValidator } from 'src/app/shared/validator/generic.validator';
import { StoreFilter } from './models/store.filter.model';
import { StoreService } from './service/store.service';
import { BreadcrumbButton } from '../../shared/models/breadcrumb-button.model';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UserValidate } from 'src/app/shared/util/UserValidate';

@Component({
  selector: 'app-list',
  templateUrl: './store.list.component.html',
  styleUrls: ['./store.list.component.scss']
})
export class StoreListComponent implements OnInit {
  filterForm: FormGroup;
  filter: StoreFilter = { number: '', cnpj: '', uf: '' };
  ufs: UF[] = getUFs();
  loading: Boolean = false;
  @Output() emitButtonData = new EventEmitter<BreadcrumbButton>();
  @ViewChild(PaginatorComponent) pagination: PaginatorComponent | undefined;
  buttonNavigate: BreadcrumbButton;
  showBtn: boolean = true;
  columns: ColumnType[] = [
    { name: 'number', title: "Lojas", type: StatusFilter.Store },
    { name: 'cnpj', title: "CNPJ", type: StatusFilter.CNPJ },
    { name: 'pdv', title: 'PDVs DocFis' }
  ]
  readonly resolution: number;
  gridColumnsSize: number;
  tileSize: number;


  constructor(public storeService: StoreService, private router: Router, private breadcrumbService: BreadcrumbService, public userValidate: UserValidate) {
    this.filterForm = new FormGroup({
      'type': new FormControl('cnpj'),
      'uf': new FormControl(''),
      'number': new FormControl(''),
      'cnpj': new FormControl(null, GenericValidator.isValidCnpj)
    });
    this.resolution = 1000;
    this.showBtn = this.userValidate.validProfile(['SELLER','DEV','SUPPORT']);
    this.gridColumnsSize = (window.innerWidth <= this.resolution) ? 2 : 5;
    this.tileSize = (window.innerWidth <= this.resolution) ? 2 : 1;
    this.buttonNavigate = { label: 'Cadastrar nova loja', path: ['store/register'] };
  }

  search(): void {
    this.filter = this.filterForm.value;
    this.filter.cnpj = this.filter.cnpj ? this.filter.cnpj.replace(/\D/g, '') : '';
    this.pagination?.search(this.filter, 0, false);
  }

  reset(form: FormGroupDirective) {
    form.resetForm();
    this.filterForm.get('uf')?.setValue('');
    this.filterForm.get('type')?.setValue('cnpj');
  }

  ngOnInit(): void {
    this.filterForm.get('type')?.valueChanges.subscribe({
      next: (value) => {
        this.filterForm.get('number')?.setValue('');
        this.filterForm.get('store')?.setValue('');
      },
    });
  }

  buttonAction() {
    this.router.navigate(this.buttonNavigate.path);
  }

  onResize(event: any) {
    this.gridColumnsSize = (event.target.innerWidth <= this.resolution) ? 2 : 5;
    this.tileSize = (event.target.innerWidth <= this.resolution) ? 2 : 1;
  }
}
