import { AbstractControl } from '@angular/forms';

export class GenericValidator {
    constructor() { }

    /**
     * Valida se o CNPJ é valido. Deve-se ser informado o cpf sem máscara.
    */
    static isValidCnpj(control: AbstractControl) {
        if (control.value == null)
            return null;
        var cnpj = control.value.replace(/[^\d]+/g, '');

        if (cnpj == '') return null;

        if (cnpj.length != 14)
            return { cpfInvalido: true };

        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return { cpfInvalido: true };

        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return { cpfInvalido: true };

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return { cpfInvalido: true };

        return null;
    }

    static isValidPhone(control: AbstractControl) {
        if (control.value == null)
            return null;
        var phone = control.value.replace(/[^\d]+/g, '');
        if (control.value == '') return null;
        if (phone.length < 10)
            return { phoneInvalido: true };

        return null;
    }
}