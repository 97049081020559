<mat-card>
    <div class="example-loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <section class="example-container" tabindex="0">
        <table mat-table [dataSource]="displayTable" class="mat-card-table rounded-border-table">
            <ng-container *ngFor="let item of columns" matColumnDef={{item.name}}>
                <th mat-header-cell *matHeaderCellDef class="table-header title"> {{item.title}} </th>
                <td mat-cell *matCellDef="let element">
                    <span class="ref" (click)="clickEvent(element)" *ngIf="item.type == statusLink">
                        {{mountByType(element[item.name],item.type)}}
                        <div class="subitem"
                            [ngStyle]="{'color': element.sefazStatus == authorized ? '#1D9077' : 'rgba(0, 0, 0, 0.54)'}">
                            {{mountByType(element.sefazStatus, statusFilter)}}
                        </div>
                    </span>
                    <div *ngIf="item.type != statusLink && item.type != storeFilter && item.type != button">
                        {{mountByType(element[item.name],item.type)}}</div>
                    <div *ngIf="item.type == storeFilter">
                        <div style="color: #0C5EC2;">
                            {{mountByTypeComposite(element[item.name], item.type, element)[0]}}
                        </div>
                        <div>
                            {{mountByTypeComposite(element[item.name], item.type, element)[1]}}
                        </div>
                    </div>
                    <div *ngIf="item.type == isCheckbox">
                        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(element) : null"
                            [checked]="selection.isSelected(element)">
                        </mat-checkbox>
                    </div>
                    <button mat-icon-button (click)="buttonAction(item, element)" *ngIf="item.type == button">
                        <mat-icon aria-hidden title="{{item.name}}">{{item.icon}}</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell">
                    Sem registros
                </td>
            </tr>
        </table>
        <br />
        <mat-paginator [length]="length + 1" [hidePageSize]="true" [pageSize]="10" aria-label="Select page">
        </mat-paginator>
    </section>
</mat-card>