import { EventEmitter, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { getUFs, UF } from 'src/app/shared/models/UF';
import { SnakBarService } from 'src/app/shared/snakbar/snakbar.service';
import { GenericValidator } from 'src/app/shared/validator/generic.validator';
import { BreadcrumbService } from 'xng-breadcrumb';
import { StoreService } from './services/store.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
  ufs: UF[] = getUFs();
  form: FormGroup;
  loading = false;
  @Output() emitEnableButton = new EventEmitter<boolean> ();

  constructor(private authService: AuthenticationService, private router: Router, private service: StoreService, private snakBar: SnakBarService, private breadcrumbService: BreadcrumbService) { 
    this.form = new FormGroup({
      'cnpj': new FormControl('', [Validators.required, GenericValidator.isValidCnpj]),
      'name': new FormControl(null, Validators.required),
      'uf': new FormControl(null, Validators.required),
      'number': new FormControl(null),
      'phone': new FormControl(null, GenericValidator.isValidPhone),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'distributionCenter': new FormControl(null),
      'address': new FormControl(null),
      'activateCode': new FormControl(null)
    })
  }

  ngOnDestroy(): void {
    this.breadcrumbService.set('@label', "Você está em ");
  }

  ngOnInit(): void {
    this.breadcrumbService.set('@label', "Gestão de lojas > ");
  }

  registerStore(formDirect: FormGroupDirective) {
    this.loading = true
    
    this.service.registerStore(this.form.value).subscribe({
      next: (value) => {
        this.form.reset({},{emitEvent:true, onlySelf: false});
        formDirect.resetForm();
        this.snakBar.success("Loja adicionada com sucesso !");
        this.loading = false;
        this.router.navigate(['store/list']);
      },
      complete: () => this.loading = false,
      error: () => this.loading = false
    })
  }

  cancel() {
    this.form.reset({},{emitEvent:true, onlySelf: false});
    this.router.navigate(['store/list']);
  }

}
