import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class RejectionService {
    constructor(private http: HttpClient) {

    }

    getRegisterHealthCheck(): Observable<String> {
        return this.http.get(`${environment.backendRegisterUrl}/health`).pipe(map(data => {
            return (<any>data).status;
        }))
    }
}