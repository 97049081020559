import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { delay, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { SnakBarService } from '../shared/snakbar/snakbar.service';
import { Menu } from './models/menu.model';
import { MenuService } from './services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  @ViewChild('beforeMenu') userMenu: any;
  showFiller = false;
  tokenExpirationTimer: any;
  private userSub: Subscription;
  menus: Set<Menu> = new Set();
  tokenSubscription = new Subscription()
  isAuthenticate = false;
  userName: any;


  constructor(private authService: AuthenticationService, private menuService: MenuService, private snackBarService: SnakBarService, private router: Router, private location: Location, private breadcrumbService: BreadcrumbService) {
    this.userSub = this.authService.userBS.subscribe(userObj => {

      if (userObj.isValid()) {
        this.isAuthenticate = true;
        this.userName = userObj.name ? userObj.name : userObj.username;
        this.autoLogout(userObj.expirationMillisseconds)
        this.menus = this.menuService.getMenus(userObj.profiles);
      } else {
        this.isAuthenticate = false;
      }
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.set('@label', "Você está em");
  }

  logout() {
    if (this.sidenav.opened) {
      this.sidenav.toggle();
    }
    this.authService.logout();
  }

  autoLogout(expirationTimeMilisseconds: any) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null).pipe(delay(new Date(expirationTimeMilisseconds * 1000))).subscribe((expired) => {
      this.snackBarService.success("Sessão expirada favor, realizar o login novamente");
      this.logout();
    });
  }

  getInitials(): string {
    return this.userName ? this.userName.charAt(0).toUpperCase() : "";
  }

  isNotHome(): boolean {
    return this.router.url !== '/';
  }

  back() {
    this.location.back();
  }

}
