import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { AuthenticationService } from './authentication.service';
import { AwsCognitoService } from './cognito.service';


@Injectable({ providedIn: 'root' })
export class TokenResolverService implements Resolve<any> {

  constructor(private location: Location,
    private awsCognitoService: AwsCognitoService,
    private router: Router,
    private authService: AuthenticationService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (!code) {
      return of(null);
    }

    return this.awsCognitoService.getTokenDetailsFromCognito(code).pipe(
      tap((resposne: any) => localStorage.setItem("refresh_token", resposne.refresh_token)),
      mergeMap((response: any) => forkJoin([of(response), this.awsCognitoService.getUserInfos(response.access_token)])),
      map((tuple: any) => new User(tuple[0]?.access_token, tuple[0]?.refresh_token, tuple[1]?.name))
    );
  }
}