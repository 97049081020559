import { Component, OnInit } from '@angular/core';
import { User } from '../authentication/models/user.model';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { MenuService } from '../header/services/menu.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  profiles: boolean = false;
  constructor(private authService: AuthenticationService, private menuService: MenuService) { 
    this.authService.userBS.subscribe(userObj => {
      this.profiles = userObj.isValid() && this.menuService.getMenus(userObj.profiles).size > 0;
    });
  }

  emailList = [
    'leonardo.veiga@amedigital.com',
    'tiago.soares@amedigital.com',
    'eric.monteiro@amedigital.com'
  ];

  ngOnInit(): void {
  }
}
