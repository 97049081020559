<div>
    <form #renewForm="ngForm" (ngSubmit)="onSubmit(renewForm)">
        <mat-card class="card">
            <mat-card-title><img src="../../assets/img/login-logo.png" /></mat-card-title>

            <div class="subtitle-card">
                <div class="subtitle-font subtitle-align margin">Crie uma nova senha</div>
                <div class="subtitle-text-font subtitle-align">Você logou usando uma senha provisória. Cadastre agora sua senha definitiva.</div>
            </div>
            
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Nova senha</mat-label>
                <input type="password" matInput required ngModel [minLength]="6" name="password">
            </mat-form-field>
            
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Confirme a nova senha</mat-label>
                <input type="password" matInput required ngModel [minLength]="6" name="newPassword">
            </mat-form-field>

            <mat-card-footer style="display: contents">
                <div>
                    <button mat-raised-button color="primary" style="border-radius: 30px; height: 50px;"
                        class="full-width btn-disabled-ame" [class.spinner]="loading" type="submit"
                        [disabled]="!renewForm.valid || loading || isInvalidPasswords(renewForm)">
                        {{loading ? "_" : "Concluir"}}
                    </button>
                </div>
                <div class="messageBox">
                    <div [ngClass]="{'green': !notEqualsPassword(renewForm), 'red' : notEqualsPassword(renewForm)}">
                        * As senhas devem ser iguais.
                    </div>
                    <div [ngClass]="{'green': !isInvalidLength(renewForm), 'red' : isInvalidLength(renewForm)}">
                        * Possuir 8 dígitos ou mais.
                    </div>
                    <div [ngClass]="{'green': !notContainsNumericChar(renewForm), 'red' : notContainsNumericChar(renewForm)}">
                        * Possuir ao menos um número.
                    </div>
                    <div [ngClass]="{'green': !notContainsUppercase(renewForm), 'red' : notContainsUppercase(renewForm)}">
                        * Possuir ao menos uma letra maiúscula.
                    </div>
                    <div [ngClass]="{'green': !notContainsSpecialChar(renewForm), 'red' : notContainsSpecialChar(renewForm)}">
                        * Possuir ao menos um caractere especial.
                    </div>
                </div>
            </mat-card-footer>
            
        </mat-card>
    </form>
    
<div style="text-align: center; padding-top: 32px; color: #fff;">Fale conosco: sac@docfis.com</div>