import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatPseudoCheckboxModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MenuService } from "../header/services/menu.service";
import { NfceService } from "../nfce/service/nfce.service";
import { CnpjMaskDirective } from "./mask/cnpj.mask.directive";
import { PhoneMaskDirective } from "./mask/phone.mask.directive";
import { PaginatorComponent } from './paginator/paginator.component';
import { CnpjPipe } from "./pipe/cnpj.pipe";
import { EmissionTypePipe } from "./pipe/emission.pipe";
import { NfcePipe } from "./pipe/nfce.pipe";
import { StatusNfcePipe } from "./pipe/status.nfce.pipe";
import { SnakbarComponent } from './snakbar/snakbar.component';
import { SnakBarService } from "./snakbar/snakbar.service";
import { UploaderModule } from "./uploader/uploader.module";
import { IntegerMaskDirective } from "./mask/integer.mask.directive";
import { NoLeadingZerosIntegerDirective } from "./mask/no.leading.zeros.integer.mask.directive";

@NgModule({
    declarations: [
        SnakbarComponent,
        PaginatorComponent,
        PhoneMaskDirective,
        CnpjMaskDirective,
        IntegerMaskDirective,
        NoLeadingZerosIntegerDirective,
        CnpjPipe,
        EmissionTypePipe,
        NfcePipe,
        StatusNfcePipe
    ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatSliderModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        MatIconModule,
        MatTableModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCardModule,
        MatSelectModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatGridListModule,
        MatListModule,
        MatDividerModule,
        MatDialogModule,
        MatExpansionModule,
        UploaderModule,
        MatPaginatorModule,
        MatPseudoCheckboxModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatSlideToggleModule
    ],
    exports: [
        CommonModule,
        MatSnackBarModule,
        MatSliderModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatTableModule,
        MatButtonModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatCardModule,
        MatSelectModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatGridListModule,
        MatDividerModule,
        MatDialogModule,
        MatExpansionModule,
        UploaderModule,
        PaginatorComponent,
        MatPaginatorModule,
        PhoneMaskDirective,
        CnpjMaskDirective,
        IntegerMaskDirective,
        NoLeadingZerosIntegerDirective,
        MatNativeDateModule,
        CnpjPipe,
        EmissionTypePipe,
        NfcePipe,
        StatusNfcePipe,
        MatPseudoCheckboxModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatSlideToggleModule
    ],
    providers: [
        SnakBarService,
        MenuService,
        NfceService, { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
    ]
})
export class SharedModule { }