// cpf.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nfce' })
export class NfcePipe implements PipeTransform {
    transform(value: string|number): string {
        let valorFormatado = value + '';

        valorFormatado = valorFormatado
            .replace(/\D/g, '')
            .replace(
                /(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})/,
                '$1 $2 $3 $4 $5 $6 $7 $8 $9 $10 $11');
        return valorFormatado;
    }
}