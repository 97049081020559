import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { SnakBarService } from "src/app/shared/snakbar/snakbar.service";
import { environment } from "src/environments/environment";
import { Store } from "../models/store.model";

@Injectable()
export class StoreService {
    constructor(private http: HttpClient, private snackBarService: SnakBarService) {

    }
    registerStore(store: Store): Observable<any> {
        store.cnpj = store.cnpj.replace(/\D/g, '')
        if (store.phone != null)
            store.phone = store.phone.replace(/\D/g, '')
        return this.http.post(`${environment.backendRegisterUrl}/store/register`, store).pipe(map(data => {
            return (<any>data);
        }))
    }

}