// cpf.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { StatusNfce, StatusNfceValue } from '../models/status.nfce';

@Pipe({ name: 'statusNfce' })
export class StatusNfcePipe implements PipeTransform {
    transform(value: string|number): string {
        var enumValue: StatusNfce = (<any>StatusNfce)[value];
        return enumValue;
    }
}