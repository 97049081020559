import { InvoiceStatus } from "src/app/seller/invoices/models/invoices.status.model"

export function getStatus(): InvoiceStatus[] {
    return [
        { name: 'Todos', value: '' },
        { name: 'Autorizada', value: 'autorizada' },
        { name: 'Cancelada', value: 'cancelada' },
        { name: 'Rejeitada', value: 'rejeitada' },
        { name: 'Inutilizada', value: 'inutilizada' },
        { name: 'Contingência', value: 'contingencia' },
    ]
}


export enum StatusNfce {
    AUTHORIZED = "Autorizada",
    CANCELED = "Cancelada",
    REJECTED = "Rejeitada",
    DISABLED = "Inutilizada",
    ERROR = "Error",
    CONTINGENCY = "Contingência"
}

export enum StatusNfceValue {
    AUTHORIZED = 'AUTHORIZED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED',
    DISABLED = 'DISABLED',
    ERROR = 'ERROR',
    CONTINGENCY = 'CONTINGENCY'
}