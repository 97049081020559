import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ResultPaginator } from '../../shared/paginator/model/paginator.result';
import { PaginatorGenericService } from '../../shared/paginator/service/paginator.generic.service';
import { environment } from 'src/environments/environment';
import { Nfce } from '../model/nfce.model';
import { NfceHistory } from '../model/nfce.history.model';
import { SnakBarService } from 'src/app/shared/snakbar/snakbar.service';

@Injectable()
export class NfceService implements PaginatorGenericService<Nfce> {
    constructor(private http: HttpClient) {
    }

    getDanfe(nfce: Nfce): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');

        return this.http.get(`${environment.backOfficeUrl}/danfe/${nfce.id}/${nfce.status}`, { headers: headers, responseType: 'blob' })
    }


    getXml(nfce: Nfce): Observable<any> {
        let headers = new HttpHeaders();

        return this.http.get(`${environment.backOfficeUrl}/nfce/download/xml?ids=${nfce.id}`, { headers: headers, responseType: 'blob' })
    }

    searchById(id: any): Observable<ResultPaginator<Nfce>> {
        return this.http.get(`${environment.backOfficeUrl}/nfce/${id}`).pipe(map((data: any) => {
            return data;
        }))
    }

    search(filter: String, limit: number, lastsKeys: string): Observable<ResultPaginator<Nfce>> {
        return this.http.get(`${environment.backOfficeUrl}/nfce/list-by-seller?limit=${limit}&${filter}&lastKeys=${lastsKeys ?? ''}`).pipe(map((data: any) => {
            return data;
        }))
    }

    history(nfce: string): Observable<NfceHistory[]> {
        return this.http.get(`${environment.backOfficeUrl}/nfce/${nfce}/history`).pipe(map((data: any) => {
            return data;
        }))
    }
}