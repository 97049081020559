export interface RenewPassworRequest {
    username: string,
    password: string,
    newPassword: string
}

export interface RenewPassworResponse {
    message: string | null,
    body: [] | null,
    token: string | null
}