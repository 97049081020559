<div>
    <button *ngIf="showBtn" class="button-navigate position-top-right"
        (click)="buttonAction()">{{buttonNavigate.label}}</button>
    <h2>Gestão de lojas</h2>
    <form #formDirective="ngForm" class="max-width" [formGroup]="filterForm" (ngSubmit)="search()">
        <mat-grid-list [cols]="gridColumnsSize" rowHeight="80px" (window:resize)="onResize($event)">
            <mat-grid-tile [colspan]="2" [rowspan]="3">
                <mat-card class="max-width">
                    <mat-card-title>Identificação da loja</mat-card-title>
                    <mat-card-content>
                        <mat-grid-list cols="2" rowHeight="90px">
                            <mat-grid-tile [colspan]="tileSize">
                                <mat-form-field appearance="outline" class="max-width">
                                    <mat-label>Tipo</mat-label>
                                    <mat-select formControlName="type" id="status" name="status" name="status">
                                        <mat-option value="cnpj">
                                            CNPJ
                                        </mat-option>
                                        <mat-option value="number">
                                            Número
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="tileSize">
                                <mat-form-field class="max-width" *ngIf="filterForm.get('type')?.value === 'cnpj'"
                                    appearance="outline">
                                    <mat-label>CNPJ da loja</mat-label>
                                    <input cnpj matInput placeholder="Cnpj da loja" formControlName="cnpj" id="cnpj"
                                        name="cnpj">
                                </mat-form-field>
                                <mat-form-field class="max-width" *ngIf="filterForm.get('type')?.value === 'number'"
                                    appearance="outline">
                                    <mat-label>Ṇº da Loja</mat-label>
                                    <input matInput type="number" placeholder="Ṇº da Loja" formControlName="number"
                                        id="number" name="number">
                                </mat-form-field>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2" [rowspan]="1"></mat-grid-tile>
            <mat-grid-tile [colspan]="2" [rowspan]="1">
                <mat-form-field appearance="outline" class="margin-ipt">
                    <mat-label>Estado</mat-label>
                    <mat-select id="estado" formControlName="uf">
                        <mat-option value=''>Todos</mat-option>
                        <mat-option *ngFor="let item of ufs" value={{item.uf}}>{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="store-buttons-fields">
                    <button type="button" id="limpar" mat-button (click)="reset(formDirective)" color="primary">Limpar
                        Pesquisa</button>
                    <button mat-flat-button type="submit" color="primary" id="pesquisar" class="search-button"
                        [disabled]="!filterForm.valid" [class.spinner]="loading">
                        {{loading ? '_': 'Pesquisar'}}
                    </button>
                </div>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2" [rowspan]="1"></mat-grid-tile>
        </mat-grid-list>

    </form>
    <hr class="left max-width">
    <table-paginator [service]="storeService" [columns]="columns" [id]="'cnpj'">
    </table-paginator>
</div>