
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { UploaderComponent } from "./uploader.component";
@NgModule({
    declarations: [
        UploaderComponent
    ],
    imports: [MatButtonModule],
    exports: [
        UploaderComponent
    ]
})
export class UploaderModule { }