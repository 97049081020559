import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SnakBarService } from '../shared/snakbar/snakbar.service';
import { AuthenticationService } from './services/authentication.service';
import { AwsCognitoService } from './services/cognito.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  isLogged = false;
  loading = false;
  constructor(private service: AuthenticationService, private router: Router, private snackBarService: SnakBarService,
    private activatedRoute: ActivatedRoute, private cognitoService: AwsCognitoService) {
    this.activatedRoute.data.subscribe(({ user }) => {
      if (user) {
        this.service.setUser(user);
        this.isLogged = true;
        this.router.navigate(['/']);
      }
    })
  }
  ngOnInit(): void {

  }

  onSubmit(authForm: NgForm) {
    if (!authForm.valid) {
      return;
    }

    const email = authForm.value.email;

    const pass = authForm.value.password;

    this.loading = true;

    this.service.login({ username: email, password: pass }).subscribe({
      next: () => {
        this.isLogged = true;
        this.router.navigate(['/']);
      },
      error: e => {

        this.loading = false;
        if (HttpStatusCode.Unauthorized == e.status) {
          this.snackBarService.error('Não foi possível autenticar. Verifique o usuário e a senha e tente novamente.');
        } else if (HttpStatusCode.PreconditionFailed == e.status) {
          this.isLogged = true;
          this.snackBarService.success('Você fez login com uma senha temporária. Registre uma nova senha.');
          localStorage.setItem("tousernameen", email);
          localStorage.setItem("oldpass", pass);
          this.router.navigate(['/renew-password']);
        } else {
          this.snackBarService.error('Ocorreu um erro. Tente novamente mais tarde.');
        }
      },
      complete: () => this.loading = false
    });
  }

  loginAmeProvider() {
    this.loading = true;
    window.location.href = `${environment.cognitoAuthorizeUrl}?identity_provider=${environment.cognitoAmeIdpName}&client_id=${environment.cognitoSSOAppClientId}&response_type=code&scope=aws.cognito.signin.user.admin+openid&redirect_uri=${environment.cognitoRedirectUrl}`;

  }
}
