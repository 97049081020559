import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AwsCognitoService {

  constructor(private http: HttpClient) { }

  public getTokenDetailsFromCognito(callbackCode: string): Observable<any> {
    const details = {
      grant_type: 'authorization_code',
      code: callbackCode,
      scope: 'openid aws.cognito.signin.user.admin',
      redirect_uri: environment.cognitoRedirectUrl
    };
    const formBody = Object.keys(details)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent((details as any)[key])}`)
      .join('&');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + btoa(`${environment.cognitoSSOAppClientId}:${environment.cognitoSSOAppSecret}`)
    });
    return this.http.post<any>(environment.cognitoTokenUrl,
      formBody, {
      responseType: 'json',
      headers: headers
    });
  }


  public getTokenFromCognito(username: string, password: string): Observable<any> {
    const details = {
      grant_type: 'authorization_code',
      username: username,
      password: password,
      scope: 'openid aws.cognito.signin.user.admin',     
      client_id: environment.cognitoSSOAppClientId,
    };
    const formBody = Object.keys(details)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent((details as any)[key])}`)
      .join('&');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + btoa(`${environment.cognitoSSOAppClientId}:${environment.cognitoSSOAppSecret}`)
    });
    return this.http.post<any>(environment.cognitoTokenUrl,
      formBody, {
      responseType: 'json',
      headers: headers
    });
  }



  public getToken(refreshToken: string): Observable<any> {
    const details = {
      grant_type: 'refresh_token',
      client_id: environment.cognitoSSOAppClientId,
      refresh_token: refreshToken,
      redirect_uri: environment.cognitoRedirectUrl
    };
    const formBody = Object.keys(details)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent((details as any)[key])}`)
      .join('&');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + btoa(`${environment.cognitoSSOAppClientId}:${environment.cognitoSSOAppSecret}`)
    });
    return this.http.post<any>(environment.cognitoTokenUrl,
      formBody, {
      responseType: 'json',
      headers: headers
    });
  }

  public getUserInfos(access_token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`
    });

    return this.http.get<any>(environment.cognitoInfoUrl, {
      responseType: 'json',
      headers: headers,
    });
  }
}