<div class="store-body">
    <form class="form" #formDirective="ngForm" [formGroup]="form" (ngSubmit)="registerStore(formDirective)" >
        <mat-card>
            <mat-card-title>Nova Loja</mat-card-title>
            <p *ngIf="form.get('cnpj')?.getError('cpfInvalido')">
                *Digite um CNPJ válido
            </p>
            <div class="store-info">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>CNPJ</mat-label>
                    <input cnpj id="cnpj" formControlName="cnpj" matInput>
                </mat-form-field>
                
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Estado</mat-label>
                    <mat-select id="uf" formControlName="uf">
                        <mat-option *ngFor="let item of ufs" value={{item.uf}}>{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Número Loja</mat-label>
                    <input type="number" id="numero" noLeadingZerosInteger formControlName="number" matInput>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input id="telefone" phone formControlName="phone" matInput>
                </mat-form-field>
                
            </div>
            <p *ngIf="form.get('email')?.getError('email')">
                *Digite um email válido
            </p> 
            <div class="store-info">
                <mat-form-field email class="full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input id="email" formControlName="email" matInput>
                </mat-form-field>

                <mat-form-field  class="full-width" appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input id="nome" formControlName="name" matInput>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Código de distribuição</mat-label>
                    <input id="codigo" formControlName="distributionCenter" matInput>
                </mat-form-field>
            </div>
            <mat-card-title>Endereço</mat-card-title>
            <div class="store-address">
                <mat-form-field class="full-width" id="address-field" appearance="outline">
                    <mat-label>Endereço</mat-label>
                    <input id="endereco" formControlName="address" matInput>
                </mat-form-field>

                <mat-form-field class="full-width" id="activation-code-field" appearance="outline">
                    <mat-label>Código de ativação</mat-label>
                    <input id="ativacao" formControlName="activateCode" matInput>
                </mat-form-field>
            </div>  
        </mat-card>
        <div class="buttons-right">
            <div class="buttons-align">
                <a class="button-cancel" (click)="cancel()">Cancelar</a>

                <button [ngClass]="{'button-save-disabled': !form.valid, 'button-save-enabled' : form.valid }"[class.spinner]="loading" type="submit" id="submit" [disabled]="!form.valid || loading" mat-raised-button color="primary">
                    {{loading ? "Salvando" : "Salvar"}}
                </button>
            </div>
        </div>
    </form>
</div>