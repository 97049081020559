import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { exhaustMap, take } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if(req.url.includes("amazoncognito")){
      return next.handle(req);
    }else if (req.url.endsWith("login") || req.url.endsWith("renew")) {
      const modifiedRequest = req.clone({
        headers: req.headers.append('Authorization', environment.serviceHeader)
      });
      return next.handle(modifiedRequest);
    } else {
      return this.authService.userBS.pipe(take(1), exhaustMap(user => {
        var dataExp = new Date(user.expirationMillisseconds * 1000);
        var now = new Date();
        if (now >= dataExp)
          this.authService.logout();
        const modifiedRequest = req.clone({
          headers: req.headers.append('Authorization', `Bearer ${user.accessToken}`)
        });
        return next.handle(modifiedRequest)
      }))

    }

  }
}