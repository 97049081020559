import { parseJWT } from "src/app/shared/jwt/jwt-parser";
import { JWT } from "./jwt.model";

export class User {
    private jwt: JWT;
    public expirationMillisseconds: number;
    public username: string
    // public cnpj: string[]
    public profiles: string[]
    constructor(
        public accessToken: string,
        public refreshToken?: string,
        public name?: string
    ) {
        this.jwt = parseJWT(accessToken);
        this.username = this.jwt?.username;
        // this.cnpj = this.jwt ? this.jwt["custom:cnpj"] : [];
        this.profiles = this.jwt ? this.jwt["cognito:groups"] : [];
        this.expirationMillisseconds = this.jwt?.exp;
    }
    public isValid(): boolean {
        return this.jwt != null;
    }
}
