import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ErrorHandlerInterceptor } from "./error-handler-interceptor.service";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
    ],

    declarations: [
        PageNotFoundComponent,
        ErrorComponent
    ],
    imports: [
        RouterModule.forChild([
            // { path: 'not-found', component: PageNotFoundComponent },
            { path: 'not-found', component: ErrorComponent, data: {message: 'Page not found!'} }
        ]),
    ]
})
export class CoreModule { }