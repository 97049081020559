import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { AuthenticationComponent } from "./authentication.component";
import { AuthInterceptorService } from "./services/authentication-interceptor.service";
import { AuthenticatonGuard } from "./services/authentication.guard";
import { AuthenticationService } from "./services/authentication.service";
import { RenewPasswordComponent } from './renew-password/renew-password.component';
import { AwsCognitoService } from "./services/cognito.service";
import { TokenResolverService } from "./services/token-resolver.service";

@NgModule({
    declarations: [
        AuthenticationComponent,
        RenewPasswordComponent
    ],
    imports: [
        FormsModule,
        SharedModule
        
    ],
    exports: [
    ],
    providers: [
        AwsCognitoService,
        TokenResolverService,
        AuthenticationService,
        AuthenticatonGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
    ]
})
export class AuthenticationModule { }