import { AfterViewInit, Component, Injectable, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { ColumnType } from './model/column.model';
import { PaginatorGenericService } from './service/paginator.generic.service';
import { StatusFilter } from './enum/status.filter.enum'
import { StatusNfce } from '../models/status.nfce';
import { SnakBarService } from '../snakbar/snakbar.service';
import { getUFs, UF } from '../models/UF';
import { SelectionModel } from '@angular/cdk/collections';
import { CnpjPipe } from '../pipe/cnpj.pipe';
@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = `Primeira Página`;
  itemsPerPageLabel = `Itens por Página:`;
  lastPageLabel = `Ultima Página`;

  nextPageLabel = 'Próxima';
  previousPageLabel = 'Anterior';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Página 1 de 1`;
    }
    // const amountPages = Math.ceil(length / pageSize);
    return `${page + 1}`;
  }
}

@Component({
  selector: 'table-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl }],
})
export class PaginatorComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [];
  dataSource: any[] = [];
  displayTable: any[] = [];
  lastKey: string = '';
  isLoadingResults: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  @Input()
  columns: ColumnType[] = [];
  readonly authorized = 'AUTHORIZED';
  readonly statusLink: StatusFilter = StatusFilter.Link;
  readonly isCheckbox: StatusFilter = StatusFilter.Checkbox;
  readonly button: StatusFilter = StatusFilter.Button;
  readonly storeFilter: StatusFilter = StatusFilter.Store;
  readonly statusFilter: StatusFilter = StatusFilter.Status;

  ufs: UF[] = getUFs();

  @Input()
  sizes: number[] = [10, 20, 50];

  @Input()
  id: string = 'id';

  @Input()
  composeId: boolean = false;

  separetedIds: string[] = this.composeId ? this.id.split(',') : [];

  length: number = 0;

  currentLimit: number = 10;

  filter: any = new Object();

  selection = new SelectionModel<any>(true, []);
  cnpj = new CnpjPipe();

  @Output() onRefClick = new EventEmitter();

  @Output() onSelectClick = new EventEmitter();

  @Input()
  service: PaginatorGenericService<any> | undefined;

  constructor(public snakbarService: SnakBarService) {
  }

  ngAfterViewInit(): void {
    this.paginator?.page.subscribe((value) => {
      if (value.pageSize !== this.currentLimit) {
        this.currentLimit = value.pageSize;
        this.clearTable();
      }
      if (this.dataSource.find(result => result.page === value.pageIndex)) {
        this.displayTable = this.dataSource.filter(filter => filter.page === value.pageIndex);
      } else {
        this.search(this.filter, value.pageIndex);
      }
    })
  }

  ngOnInit(): void {
    this.displayedColumns = this.columns.map(value => value.name);
    this.selection.changed.subscribe({
      next: (_) => {
        this.onSelectClick.emit(this.selection.hasValue());
      }
    })
  }

  clickEvent(value: any): void {
    this.onRefClick.emit(value);
    this.isLoadingResults = true;
  }

  buttonAction(element: ColumnType, value: any): void {
    this.isLoadingResults = true;
    element.action(this, value);
  }

  searchById(id: any) {
    this.isLoadingResults = true;
    this.clearTable();
    this.service?.searchById(id).subscribe({
      next: (value) => {
        this.displayTable = [value];
      }, error: (err) => {
        this.snakbarService.error("Ocorreu um erro na requisição, favor verificar : " + err.error.message);
        console.error(err);
        this.isLoadingResults = false;
      },
      complete: () => {
        this.isLoadingResults = false;
      }
    })
  }

  search(filter: any, index: number, useLastKey: boolean = true) {
    this.filter = filter;
    this.isLoadingResults = true;
    if (useLastKey === false) {
      this.clearTable();
    }
    this.service?.search(new URLSearchParams(filter).toString(), this.paginator?.pageSize ?? 10, useLastKey && index !== 0 ?
      JSON.stringify(this.lastKey).replace(/[^a-zA-Z0-9:,_-]/g, '')
      : '').subscribe({
        next: (value) => {
          value.results.forEach(value => value.page = index);
          value.results.forEach(result => {
            if (this.composeId) {
              if (!this.dataSource.find(data => data[this.separetedIds[0]] + data[this.separetedIds[1]] === result[this.separetedIds[0]] + result[this.separetedIds[1]]))
                this.dataSource.push(result)
            } else {
              if (!this.dataSource.find(data => data[this.id] === result[this.id]))
                this.dataSource.push(result)
            }
          });
          this.displayTable = this.dataSource.filter(filter => filter.page === index);
          this.lastKey = value.lastKeys
          this.length = this.dataSource.length;
        },
        error: (err) => {
          this.snakbarService.error("Ocorreu um erro na requisição, favor verificar : " + err.message);
          console.error(err);
          this.isLoadingResults = false;
        },
        complete: () => {
          this.isLoadingResults = false;
        }
      });
  }

  clearTable() {
    this.dataSource = [];
    this.displayTable = [];
    this.length = 0;
    this.paginator?.firstPage();
  }

  clearTablePageFirst() {
    this.paginator?.firstPage();
    this.dataSource = [];
    this.displayTable = [];
    this.length = 0;
  }

  mountByType(value: string, type: StatusFilter | undefined): string {
    switch (type) {
      case StatusFilter.Status:
        var enumValue: StatusNfce = (<any>StatusNfce)[value];
        return enumValue
      case StatusFilter.Date:
        var date = new Date(value);
        return `${date.toLocaleTimeString('pt-BR')} ${date.toLocaleDateString('pt-BR')}`
      case StatusFilter.UF:
        return `${value} - ${this.ufs.find(uf => uf.uf === value)?.name}`
      case StatusFilter.CNPJ:
        return `${this.cnpj.transform(value)}`
      default:
        return value;
    }
  }

  mountByTypeComposite(value: string, type: StatusFilter | undefined, subitem: any): string[] {
    switch (type) {
      case StatusFilter.Store:
        return [(value || '0'), `${subitem.uf} - ${this.ufs.find(uf => uf.uf === subitem.uf)?.name}`];

      default:
        return [value, subitem];
    }
  }

}
