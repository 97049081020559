<div class="full-height">
  <mat-toolbar color="primary" *ngIf="isAuthenticate">

    <mat-toolbar-row class="absolute">
      <span class="docfis-logo"><img src="../../assets/img/docfis-logo.png"></span>
      <span class="right">
        <span class="avatar">{{getInitials()}}</span>
        <span class="user-name">{{userName}}</span>
        
        <button  mat-button [matMenuTriggerFor]="userMenu"><mat-icon class="user-options-icon">keyboard_arrow_down</mat-icon></button>
        <mat-menu #userMenu="matMenu" xPosition="before">
          <button (click)="logout()" mat-menu-item>Sair</button>
        </mat-menu>
      </span>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill class="full-height">
    <mat-sidenav #sidenav mode="side" *ngIf="isAuthenticate" opened autosize [fixedInViewport]="true" class="shadow_right" >
      
      <div [ngClass]="{'menu-header open-menu-button': !showFiller, 'menu-header close-menu-buton' : showFiller}" >
        <button mat-icon-button (click)="showFiller = !showFiller;" fxShow="true" fxHide.gt-sm>
          <mat-icon *ngIf="!showFiller">menu</mat-icon>
          <mat-icon *ngIf="showFiller" >close</mat-icon>
        </button>
      </div>

      <mat-nav-list (click)="showFiller = false" class="menu">
        <a *ngFor="let item of menus" routerLink={{item.url}} mat-list-item>
            <img class="menu-item" src="{{item.icon}}" alt="{{item.name}}" title="{{item.name}}"/>
          <div *ngIf="showFiller" routerLink={{item.url}} title="{{item.name}}" mat-list-item class="menu-item menu-text">{{item.name}}</div>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    
    
    <mat-sidenav-content class="background-color" #content fxFlexFill [ngClass]="{'sidenav-auth-height' : isAuthenticate, 'scroll-hidden': !isAuthenticate}">

      <div class="xng-breadcrumb-root" *ngIf="isAuthenticate && isNotHome()">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let last = last">
            <span class="breadcrumb-text" *ngIf="breadcrumb">{{breadcrumb}}</span> 
            <span *ngIf="info" class="breadcrumb-margin bold">{{info}}</span>
          </ng-container>
          <ng-template #iconTemplate>
            <mat-icon>chevron_right</mat-icon>
          </ng-template>
        </xng-breadcrumb>
      </div>
      <div [ngStyle]="{'padding' : isAuthenticate ? '20px' : ''}" [ngClass]="{'full-height' : !isAuthenticate}">
        <router-outlet></router-outlet>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
