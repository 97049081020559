import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';
import { SnakBarService } from 'src/app/shared/snakbar/snakbar.service';
import { AuthenticationRequest, AuthenticationResponse } from '../models/authentication.model';
import { User } from '../models/user.model';
import { environment } from '../../../environments/environment';
import { RenewPassworRequest, RenewPassworResponse } from '../models/renew-password.model';
import { AwsCognitoService } from './cognito.service';

@Injectable()
export class AuthenticationService {
    tokenExpirationTimer: any;
    tokenSubscription = new Subscription()
    user = new User("");
    userBS = new BehaviorSubject<User>(this.user);
    constructor(private http: HttpClient, private router: Router, private snackBarService: SnakBarService, private cognitoClient : AwsCognitoService) {
    }

    login(input: AuthenticationRequest): Observable<AuthenticationResponse> {
        return this.http.post<AuthenticationResponse>(`${environment.backendRegisterUrl}/login`, input).pipe(tap(
            resData => {
                this.handleAuthentication(
                    resData.token, input.username
                );
            }));
    }

    renew(input: RenewPassworRequest): Observable<any> {
        return this.http.post<any>(`${environment.backendRegisterUrl}/login/renew`, input).pipe(tap(
            () => {
                localStorage.removeItem("oldpass");
                this.snackBarService.success("Senha atualizada com sucesso.");
            }));
    }

    private handleAuthentication(token: string, username: string) {
        this.setUser(new User(token));
        localStorage.setItem("userData", JSON.stringify(this.user));
        localStorage.setItem("tousernameen", username);
        this.snackBarService.success("Bem vindo " + username);
    }

    autoLogin() {
        const userData = localStorage.getItem('userData');
        if (!userData) {
            return;
        }
        const object = JSON.parse(userData);
        this.setUser(new User(object.accessToken));
    }
    setUser(newUser : User){
        this.user = newUser;
        this.userBS.next(newUser);
    }

    logout() {
        localStorage.removeItem('userData');
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }
        this.setUser(new User(""));
        this.router.navigate(['/login']);
    }
  
}