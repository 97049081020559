import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
	selector: 'btn-uploader',
	templateUrl: './uploader.component.html'
})
export class UploaderComponent {
	public buttonText = '';
	@Input()
	set text(name: string) {
		this.buttonText = name;
	}
	get name(): string {
		return this.buttonText;
	}
	
	@Input() type: string | undefined;
	@Input() id: string = "file";
	@Output() uploadComplete = new EventEmitter();
	@Input() isDisabled = false;
	fileName : string;
	private readonly defaultFileName = "Clique no botão para selecionar arquivo";

	constructor() { 
		this.fileName = this.defaultFileName;
	}

	convertFile(file: File): Observable<string> {
		const result = new ReplaySubject<string>(1);
		const reader = new FileReader();
		reader.readAsBinaryString(file);
		reader.onload = (event: any) => result.next(btoa(event.target.result.toString()));
		return result;
	}

	onChange(event: any) {
		var file = event.target.files[0];
		this.fileName = file.name;
		this.convertFile(file).subscribe(base64 => {
			this.uploadComplete.emit(base64);
		});
	}

	resetFileName() {
		this.fileName = this.defaultFileName;
	}

}
