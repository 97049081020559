//const backendUrl = "https://api-docfis.hml.amedigital.com/docfis";
//const cognitoUrl = "https://ame-hml-docfis.auth.us-east-1.amazoncognito.com";
const backendUrl = "https://api-docfis-hml.internal.americanas.io/docfis";
const cognitoUrl = "https://hml-docfis.auth.us-east-1.amazoncognito.com";

export const environment = {
  production: false,
  serviceHeader: "A3685656-C9CC-4974-BD22-B027ED410A2C",
  backendRegisterUrl: `${backendUrl}/auth`,
  backendSyncUrl: `${backendUrl}/sync`,
  backOfficeUrl: `${backendUrl}/backoffice`,


  cognitoSSOAppClientId: '3baqkqndpmn2tate02o2aoups8',
  cognitoSSOAppSecret:"hps8c6abii3if10n3ceoma7kiq9pkfkesmhq3e0c0ig7j1f7d94",
  cognitoAmeIdpName:"AmeDigitalProvider",
  cognitoTokenUrl: `${cognitoUrl}/oauth2/token`,
  cognitoInfoUrl: `${cognitoUrl}/oauth2/userInfo`,
  cognitoAuthorizeUrl: `${cognitoUrl}/oauth2/authorize`,

  //cognitoRedirectUrl: 'https://docfis.hml.amedigital.com/',
  cognitoRedirectUrl: 'https://docfis-hml.internal.americanas.io/',
};