import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RejectionService } from './services/rejections.service';

@Component({
  selector: 'app-rejections',
  templateUrl: './rejections.component.html',
  styleUrls: ['./rejections.component.scss']
})
export class RejectionsComponent implements OnInit {
  loginStatus: any;
  constructor(private httpClient: HttpClient, private service: RejectionService) { }

  ngOnInit(): void {
    this.onGet();
  }

  onGet(){
    this.service.getRegisterHealthCheck().subscribe(value => {
      this.loginStatus = value;
    })
  }
}
