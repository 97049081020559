import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[formControlName][noLeadingZerosInteger]',
})
export class NoLeadingZerosIntegerDirective {
    constructor(public ngControl: NgControl) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event: any) {
        this.onInputChange(event);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event: any) {
        this.onInputChange(event.target.value, true);
    }

    onInputChange(event: any, backspace: boolean = false) {
        let newVal = '';
        if (event !== null && Number.isNaN(event))
            newVal = event.replace(/\D/g, '');

        if (backspace && newVal.length <= 2) {
            newVal = newVal.substring(0, newVal.length - 1);
            if (event.length === 3) {
                this.ngControl.reset();
                this.ngControl.control?.markAsPristine();
            }
        }
        const integerValue = parseInt(event);
        const noLeadingZerosInteger = Number.isNaN(integerValue) ? null : integerValue;
        this.ngControl.valueAccessor?.writeValue(noLeadingZerosInteger);
    }
}