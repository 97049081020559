import { Injectable } from "@angular/core";
import { map, Observable, take } from "rxjs";
import { AuthenticationService } from "src/app/authentication/services/authentication.service";

@Injectable({
    providedIn: 'root'
  })
export class UserValidate {
    constructor(private autService: AuthenticationService) { }
    validProfile(profiles: string[]): boolean {
        var profilesUser = this.autService.userBS.getValue().profiles;
        if (profiles.includes("ANY"))
            return true;
        for (let index = 0; index < profilesUser.length; index++) {
            const element = profilesUser[index];
            if (profiles.includes(element))
                return true;
        }
        return false;
    }
}



