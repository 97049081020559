<div>
<form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
            <mat-card class="card">
                <mat-card-title><img src="../../assets/img/login-logo.png"/></mat-card-title>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input type="email" matInput email required ngModel placeholder="Ex. pat@example.com"
                            name="email">
                        <mat-error *ngIf="authForm.hasError('email') && !authForm.hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="authForm.hasError('required')">
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Senha</mat-label>
                        <input type="password" matInput required ngModel [minLength]="6" name="password">
                        <mat-error *ngIf="authForm.hasError('required')">
                            Password is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-card-content class="text text-left">
                        <span style="color: #0C5EC2;" >Esqueci minha senha</span>
                    </mat-card-content>
                <br/>
                <mat-card-footer style="display: contents">
                    <div>
                        <button mat-raised-button color="primary" style="border-radius: 30px; height: 50px;" class="full-width btn-disabled-ame" [class.spinner]="loading" type="submit" [disabled]="!authForm.valid || loading" >
                           {{loading ? "_" : "Entrar"}}
                        </button>
                    </div>  
                </mat-card-footer>
            </mat-card>
</form>
<div style="text-align: center; padding-top:32px;">
    <a style="color: #2fc3fd; cursor: pointer;" (click)="loginAmeProvider()" matTooltip="Logue com as credenciais da AmeDigital"  matTooltipPosition="below">
        Entre com SSO
    </a>
</div>
</div>
