import { Injectable } from '@angular/core';
import { Menu } from '../models/menu.model';

@Injectable()
export class MenuService {
    urls: Array<Menu> = new Array();
    constructor() {
        const iconFolderPath = 'assets/icons/';

        this.urls = [
            {icon: iconFolderPath + 'order.svg', name: 'Pesquisa de notas fiscais', profile: ['SELLER','SUPPORT','DEV','CUSTOMER'], url: '/nfce'},
            {icon: iconFolderPath + 'store.svg', name: 'Lojas', profile: ['SELLER','SUPPORT','DEV','CUSTOMER'], url: '/store/list'},
            {icon: iconFolderPath + 'certified.svg', name: 'Gestão de certificados', profile: ['SELLER','SUPPORT'], url: '/certificate/register'},
            {icon: iconFolderPath + 'secure_key_hole.svg', name: 'Gestão de CSC', profile: ['SELLER','SUPPORT'], url: '/csc'}
        ];
    }

    public getMenus(profiles: Array<String>) : Set<Menu> {
        var result: Set<Menu> = new Set();
        var profilesMenu = profiles?.map(profile => {
            return this.urls.filter(url => 
                url.profile.includes(profile)
            );
        });
        profilesMenu?.forEach(menus => {
            menus.forEach(r => {
                result.add(r);
            })
        })
        return result;
    }


}