import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SnakBarService } from 'src/app/shared/snakbar/snakbar.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-renew-password',
  templateUrl: './renew-password.component.html',
  styleUrls: ['./renew-password.component.scss']
})
export class RenewPasswordComponent implements OnInit {
  isLogged = false;
  loading = false;
  showPassword = false;

  constructor(private service: AuthenticationService, private router: Router, private snackBarService: SnakBarService) { }

  ngOnInit(): void {
  }

  onSubmit(renewForm: NgForm) {
    if (!renewForm.valid) {
      return;
    } else if (this.isInvalidPasswords(renewForm)) {
      this.snackBarService.error("Verifique as informações digitadas nos campos");
      return;
    }

    this.loading = true;
    const usr = localStorage.getItem("tousernameen") || "";
    const oldPass = localStorage.getItem("oldpass") || "";
    const newPassword = renewForm.value.newPassword;

    this.service.renew({ username: usr, password: oldPass, newPassword: newPassword }).subscribe({
      next: () => {
        this.service.login({ username: usr, password: newPassword }).subscribe({
          next: () => {
            this.isLogged = true;
            this.router.navigate(['/']);
          },
          error: e => this.loading = false,
          complete: () => {
            this.loading = false;
            this.snackBarService.success("Nova senha cadastrada com sucesso!");
          }
        });
      },
      error: e => {this.loading = false;
        this.snackBarService.error("Ocorreu um erro ao criar uma nova senha. Tente novamente!");
      },
      complete: () => this.loading = false
    });
  }

  isInvalidPasswords(renewForm: NgForm): boolean {
    return this.isInvalidLength(renewForm)
    || this.notEqualsPassword(renewForm)
    || this.notContainsNumericChar(renewForm)
    || this.notContainsSpecialChar(renewForm)
    || this.notContainsUppercase(renewForm);
  }

  isInvalidLength(renewForm: NgForm): boolean {
    const password = renewForm.value.password;
    const newPassword = renewForm.value.newPassword;
    const minLength = 8;

    return !(password?.length >= minLength || newPassword?.length >= minLength);
  }

  notEqualsPassword(renewForm: NgForm): boolean {
    const password = renewForm.value.password;
    const newPassword = renewForm.value.newPassword;

    return !(password?.length > 0 && newPassword?.length > 0) || password !== newPassword;
  }

  notContainsNumericChar(renewForm: NgForm): boolean  {
    const numberRegex = /\d+/;

    return !(numberRegex.exec(renewForm.value.password) || numberRegex.exec(renewForm.value.newPassword));
  }

  notContainsUppercase(renewForm: NgForm): boolean {
    const upperCaseRegex = /[A-Z]/;

    return !(upperCaseRegex.exec(renewForm.value.password) || upperCaseRegex.exec(renewForm.value.newPassword));
  }

  notContainsSpecialChar(renewForm: NgForm): boolean {
    const specialCharRegex = /[!@#$%^&*(),.?"'`:;{}|<>ªº~_+=\-\[\]\/\\]/;

    return !(specialCharRegex.exec(renewForm.value.password) || specialCharRegex.exec(renewForm.value.newPassword));
  }

}
