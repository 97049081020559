import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { catchError, finalize, map, Observable, of, switchMap, take } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { AwsCognitoService } from "./cognito.service";

@Injectable()
export class AuthenticatonGuard implements CanActivate {
    constructor(private awsCognitoService: AwsCognitoService, private autService: AuthenticationService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.autService.userBS.pipe(take(1), map(user => {
            if(!user.isValid())
                return this.router.createUrlTree(['/login']);
            var dataExp = new Date(user.expirationMillisseconds * 1000);
            var now = new Date();
            if (now >= dataExp)
                this.autService.logout();
            let profiles = route.data["roles"] as string[];
            if (user.isValid() && validProfile(profiles, user.profiles)) {
                return true;
            }
            return this.router.createUrlTree(['/not-found']);
        }))
    }
}
export function canActivateForRoles(roles: string[]) {
    return { data: { roles }, canActivate: [AuthenticatonGuard] }
}

function validProfile(profiles: string[], userProfiles: string[]) {
    if (profiles.includes("ANY"))
        return true;
    for (let index = 0; index < userProfiles.length; index++) {
        const element = userProfiles[index];
        if (profiles.includes(element))
            return true;
    }
    return false;
}

