<h1 *ngIf="profiles">Bem vindo ao DOCFIS</h1>
<div *ngIf="!profiles" class="profile-body" >
    <div>
        <img class="add-user-icon" src="assets/icons/add_user.png"/>
    </div>
    <b class="header-welcome-text">Ops! Você não tem um perfil de acesso
        cadastrado no sistema.</b>
    <p class="text-font justify">Solicite a criação do seu perfil entrando em contato com o time do Doc Fis através de um destes e-mails:</p>
    <div *ngFor="let email of emailList" class="text-font">
        <b>{{email}}</b>
    </div>
    <p class="text-font justify">No corpo do e-mail, informe sua empresa e o tipo de perfil de acesso que você deseja obter: 
        <b> suporte, fiscal</b> ou <b>administrador.</b></p>
<div>
