import { Injectable } from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar';
import { SnakbarComponent } from "./snakbar.component";

@Injectable({providedIn: 'root'})
export class SnakBarService {
    constructor(private snakBar: MatSnackBar) {

    }

    success(msg : string){
        this.snakBar.open(msg, "X", {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'notify-success',
            duration: 5000
        });
    }

    warning(msg: string) {
        this.snakBar.open(msg, "X", {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'notify-warning',
            duration: 5000
        });
    }

    error(msg : string){
        this.snakBar.open(msg, "X", {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'notify-error',
            duration: 10000
        });
    }

    error2(msg : string){
        this.snakBar.openFromComponent(SnakbarComponent,{
            data: msg,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'notify-error',
            duration: 10000
        });
    }
}