import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEventType,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { SnakBarService } from '../shared/snakbar/snakbar.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private snakBarService: SnakBarService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(tap(event => {
      if (event.type === HttpEventType.Response) {
        // console.log('Incoming response');
        // console.log(event.body);
      }
    }
    ),
      catchError((error: HttpErrorResponse) => {
        this.snakBarService.error(error.error?.message);
        return throwError(() => error);
      }))
  }
}