import { JWT } from "src/app/authentication/models/jwt.model";

export function getProfiles(jwt: JWT): string[] {
    return jwt["cognito:groups"];
}   
export function getSellers(jwt: JWT): string[] {
    return jwt["custom:cnpj"];
}  

export function getName (jwt: JWT): string {
    return jwt?.name;
}   
export function getUserName(jwt: JWT): string {
    return jwt?.username;
}   
export function getExpirationMillisseconds(jwt: JWT): number {
    return jwt?.exp;
}

function b64DecodeUnicode(str: string){
    return decodeURIComponent(
        Array.prototype.map.call(atob(str), c =>
            '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        ).join(''))
    } 
export function parseJWT(token: string){
    if(!token && token === ''){
        return null;
    }
    return JSON.parse(
        b64DecodeUnicode(
            token.split('.')[1].replace('-', '+').replace('_', '/')
        )
    )
};