// cpf.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emission' })
export class EmissionTypePipe implements PipeTransform {
    transform(value: string|number): string {
        switch (value) {
            case "1":
                return 'Normal'
            case "9":
                return 'Contingência'
            default:
                return 'Não definido';
        }

    }
}